export function trackClick (name: string): void {
  try {
    window.plausible('Link Clicked', {
      props: {
        name
      }
    });
  } catch (e) {

  }
}

export function subscribedToCreator (): void {
  try {
    window.plausible('Subscribed To Creator');
  } catch (e) {

  }
}

export function paymentMethodsPreview (): void {
  try {
    window.plausible('Payment Methods Preview');
  } catch (e) {

  }
}

export function paymentPreview (method: 'eth' | 'card'): void {
  try {
    window.plausible('Payment Preview', { props: { method: method } });
  } catch (e) {

  }
}

export function paymentAttempt (
  method: 'eth' | 'card',
  tokensToMint: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet'
): void {
  try {
    window.plausible('Payment Attempt', { props: { method, tokensToMint, deliveryTo } });
  } catch (e) {

  }
}

export function paymentSuccess (
  method: 'eth' | 'card',
  tokensMinted: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet',
  submethod: string
): void {
  try {
    window.plausible('Payment Success', {
      props: {
        method,
        tokensMinted,
        deliveryTo,
        submethod
      }
    });
  } catch (e) {

  }
}

export function paymentError (
  method: 'eth' | 'card',
  tokensToMint: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet',
  error = 'unknown'
): void {
  try {
    window.plausible('Payment Error', { props: { method, tokensToMint, deliveryTo, error } });
  } catch (e) {

  }
}
