<template>
  <button
    class="checkout-payment-method-button"
    type="button"
    @click="$emit('clicked')"
  >
    <span>Pay with ETH</span>
    <div class="checkout-payment-method-logos">
      <img src="@/assets/images/metamask-logo.png" alt="MetaMask" />
      <img src="@/assets/images/walletconnect-logo.png" alt="WalletConnect" />
      <img src="@/assets/images/coinbase-wallet-logo.png" alt="Coinbase Wallet" />
    </div>
  </button>
</template>

<style lang="scss" scoped>
.checkout-payment-method-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: var(--border);
  background: var(--backgroundColor);
  color: var(--foregroundColor);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-transform: uppercase;

  .checkout-payment-method-logos {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1.5rem;
    }

    > * + * {
      margin-left: 0.5rem;
    }
  }
}
</style>
