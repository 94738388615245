import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from '@ethersproject/bignumber';
import { useMutation, UseMutationOptions } from '@tanstack/vue-query';
import { ref, unref } from 'vue';
import { apiHelpers } from '@/api/apiHelpers';
import {
  sleep
} from '@/lib/claimUtil';
import { useClaimStore } from '@/store/claimStore';

type UseApproveOptions = UseMutationOptions<
{ tx: TransactionResponse; },
unknown,
unknown,
unknown
>

export function useApprove (options?: UseApproveOptions) {
  const store = useClaimStore();

  const tx = ref<TransactionResponse>();
  const txState = ref<'idle' | 'confirming' | 'success' | 'error'>('idle');
  const tokenIds = ref<BigNumber[]>();

  const { mutate: approve, mutateAsync: approveAsync, ...rest } = useMutation(
    async () => {
      txState.value = 'idle';

      if (!store.isConnected || !store.mintForWallet || !store.walletAddress) {
        throw new Error('Connect your wallet to continue');
      }

      const _tx: TransactionResponse = await store.erc20Contract.approve(
        store.walletAddress,
        store.extensionAddress
      );
      tx.value = _tx;
      txState.value = 'confirming';

      apiHelpers.post('/public/instance/tx', {
        query: {
          appId: store.appId,
          instanceId: store.id,
          hash: _tx.hash
        }
      }).catch(() => {
        console.error('Failed to post tx hash to public instance api');
      });

      if (!store.isProviderAvailable) {
      // HACK: since claims supports minting without a provider
      // (e.g. walletconnect) we don't have the ability
      // to wait until the transaction has been mined.
      // Pausing here allows us to have a good chance
      // that the block will be mined before a ui
      // refresh happens which avoids a number of
      // confusing ui edge cases.
        await sleep(60_000);
      } else {
        await _tx.wait(1);
      }

      store.refreshWeb3State();
      txState.value = 'success';

      return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tx: unref(tx)!
      };
    },
    {
      ...options,
      onError: (err, variables, context) => {
        txState.value = 'error';
        options?.onError?.(err, variables, context);
      }
    });

  return {
    ...rest,
    approve,
    approveAsync,
    tx,
    txState,
    tokenIds
  };
}
