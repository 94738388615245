// platform constants

/** supported platforms for display */
export enum DisplayPlatform {
  Foundation = 'Foundation',
  Manifold = 'Manifold',
  Rarible = 'Rarible',
  LooksRare = 'LooksRare',
  OpenSea = 'OpenSea'
}

/** props for platform link display */
interface PlatformDisplayProps {
  /** supported chain ids */
  networks: Readonly<number[]>;
  /** icon to display */
  imageProps: { src: string, class?: string };
}

export const PLATFORM_DISPLAY_PROPS: {[platform in DisplayPlatform]: PlatformDisplayProps} = {
  [DisplayPlatform.Foundation]: {
    networks: [1],
    imageProps: { src: 'icon-foundation.svg' }
  },
  [DisplayPlatform.Manifold]: {
    networks: [1, 5, 10],
    imageProps: { src: 'manifold-logograph.svg', class: 'manifold-logo' }
  },
  [DisplayPlatform.Rarible]: {
    networks: [1],
    imageProps: { src: 'icon-rarible.svg' }
  },
  [DisplayPlatform.LooksRare]: {
    networks: [1],
    imageProps: { src: 'icon-looksrare.svg' }
  },
  [DisplayPlatform.OpenSea]: {
    networks: [1, 5, 10],
    imageProps: { src: 'icon-opensea.svg' }
  }
} as const;

/** order in which to display platform links */
export const PLATFORM_DISPLAY_ORDER: DisplayPlatform[] = [DisplayPlatform.OpenSea, DisplayPlatform.LooksRare, DisplayPlatform.Rarible, DisplayPlatform.Foundation, DisplayPlatform.Manifold];

/** calculate the opensea prefix based on network (no trailing /) */
export function getOpenSeaBaseLink (networkId: number) {
  if (networkId === 10) {
    return 'https://opensea.io/assets/optimism';
  } else if (networkId === 5) {
    return 'https://testnets.opensea.io/assets/goerli';
  } else {
    // default to ethereum mainnet
    return 'https://opensea.io/assets/ethereum';
  }
}

/** calculate manifold gallery prefix based on network (no trailing /) */
export function getGalleryBaseLink (networkId: number) {
  if (networkId === 10) {
    return 'https://gallery.manifold.xyz/optimism';
  } else if (networkId === 5) {
    return 'https://gallery.manifold.xyz/goerli';
  } else {
    return 'https://gallery.manifold.xyz';
  }
}

/** calculate the link based on the token details */
export function getPlatformLink (platform: DisplayPlatform, networkId: number, contractAddress: string, tokenId: string | null) {
  switch (platform) {
    case DisplayPlatform.OpenSea:
      return `${getOpenSeaBaseLink(networkId)}/${contractAddress}${tokenId ? `/${tokenId}` : ''}`;
    case DisplayPlatform.LooksRare:
      return `https://looksrare.org/collections/${contractAddress}${tokenId ? `/${tokenId}` : ''}?ref=9323239`;
    case DisplayPlatform.Rarible:
      if (tokenId) {
        return `https://rarible.com/token/${contractAddress}:${tokenId}`;
      }
      return `https://rarible.com/collection/${contractAddress}`;
    case DisplayPlatform.Foundation:
      return `https://foundation.app/collection/${contractAddress}`;
    case DisplayPlatform.Manifold:
      // absolutely needs tokenId
      return `${getGalleryBaseLink(networkId)}/${contractAddress}/${tokenId}`;
  }
}
