import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { delegationRegistryAbi } from '@/abis';
import { _callReadWeb3WithManifoldBridgeFallback } from './utils/web3Call';

export enum DelegationType {
  NONE,
  ALL,
  CONTRACT,
  TOKEN
}
export interface IDelegate {
  contract_: string;
  delegate: string;
  tokenId: BigNumber;
  type_: DelegationType;
  vault: string
}
export class DelegationRegistryContract {
    private networkId: number
    private delegationContractAddress : string

    constructor (networkId: number, delegationContractAddress: string) {
      this.networkId = networkId;
      this.delegationContractAddress = delegationContractAddress;
    }

    protected _getContractInstance () : Contract {
      const contract = window.ManifoldEthereumProvider.contractInstance(this.delegationContractAddress, delegationRegistryAbi, false);
      if (!contract) throw new Error('No contract instance available, please refresh this page to try again');
      return contract;
    }

    async getDelegatesForContract (vault: string, contractAddress: string) : Promise<string[]> {
      return _callReadWeb3WithManifoldBridgeFallback(this.networkId, { contractAddress: this.delegationContractAddress, abi: delegationRegistryAbi, functionName: 'getDelegatesForContract', args: [vault, contractAddress] });
    }

    async getDelegatesForAll (vault: string) : Promise<string[]> {
      return _callReadWeb3WithManifoldBridgeFallback(this.networkId, { contractAddress: this.delegationContractAddress, abi: delegationRegistryAbi, functionName: 'getDelegatesForAll', args: [vault] });
    }

    async getDelegationsByDelegate (delegate: string) : Promise<IDelegate[]> {
      return _callReadWeb3WithManifoldBridgeFallback(this.networkId, { contractAddress: this.delegationContractAddress, abi: delegationRegistryAbi, functionName: 'getDelegationsByDelegate', args: [delegate] });
    }
}
