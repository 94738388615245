import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { markRaw } from 'vue';
import { ManifoldBridgeProvider } from '@manifoldxyz/manifold-provider-client';
import { erc20Abi } from '@/abis/ERC20ABI';

const MAX_APPROVE = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

interface ContractError {
  code: string;
  cancelled: boolean;
  replacement: { hash: string };
}

class ERC20Contract {
  private networkId: number;
  private contractAddress: string;

  // Manifold bridge provider instance
  private manifoldBridgeProvider: ManifoldBridgeProvider | undefined;

  constructor (
    networkId: number,
    contractAddress: string
  ) {
    this.networkId = networkId;
    this.contractAddress = contractAddress;
  }

  protected _getContractInstance (
    withSigner = false,
    bridge = false,
    unchecked = false
  ): Contract {
    if (bridge) {
      return new Contract(
        this.contractAddress,
        erc20Abi,
        this._getManifoldBridgeProvider()
      );
    }
    const contract = window.ManifoldEthereumProvider.contractInstance(
      this.contractAddress,
      erc20Abi,
      withSigner,
      unchecked
    );
    if (!contract) {
      throw new Error(
        'No contract instance available, please refresh this page to try again'
      );
    }
    return contract;
  }

  async getAllowance (
    spender: string,
    owner: string
  ): Promise<BigNumber> {
    return await this._callWeb3WithServerFallback(
      'allowance',
      [owner, spender]
    );
  }

  async getERC20Name (): Promise<string> {
    return await this._callWeb3WithServerFallback(
      'name',
      []
    );
  }

  async getERC20Symbol (): Promise<string> {
    return await this._callWeb3WithServerFallback(
      'symbol',
      []
    );
  }

  async getERC20Decimals (
  ): Promise<number> {
    return this._callWeb3WithServerFallback(
      'decimals',
      []
    );
  }

  async approve (
    walletAddress: string,
    spender: string
  ): Promise<TransactionResponse> {
    let unchecked = false;
    try {
      // if using wallet connect with no provider, we will use wallet connect built in provider to make write calls
      if (
        localStorage.getItem('connectMethod') &&
        localStorage.getItem('connectMethod') === 'walletConnect'
      ) {
        unchecked = true;
      }

      const gasLimit = await this.estimateGasApprove(
        walletAddress,
        spender
      );

      return await this._getContractInstance(
        true,
        false,
        unchecked
      ).approve(
        spender,
        MAX_APPROVE,
        {
          gasLimit
        }
      );
    } catch (e: any) {
      return await this.errorHandling(e);
    }
  }

  async estimateGasApprove (
    walletAddress: string,
    spender: string
  ): Promise<BigNumber> {
    const args = [spender, MAX_APPROVE, { from: walletAddress }];
    const functionSig = 'approve(address,uint256)';

    return this._estimateGas3WithServerFallback(functionSig, args);
  }

  async _estimateGas3WithServerFallback (
    functionSig: string,
    args: any[]
  ): Promise<BigNumber> {
    if (!window.ManifoldEthereumProvider.chainIsCorrect()) throw new Error('Wrong Network');
    let gasEstimate;
    try {
      gasEstimate = await this._getContractInstance(true).estimateGas[
        functionSig
      ](...args);
    } catch (e) {
      // get etimate from manifold bridge instead
      gasEstimate = await this._getContractInstance(
        true,
        true
      ).estimateGas[functionSig](...args);
    }

    // Multiply gas estimate by 1.25 to account for inaccurate estimates from Metamask.
    gasEstimate = gasEstimate.mul((1 + 0.25) * 100).div(100);
    return gasEstimate;
  }

  async _callWeb3WithServerFallback (
    functionName: string,
    args: any[]
  ): Promise<any> {
    const provider = window.ManifoldEthereumProvider.provider();
    // @ts-ignore
    if (!provider) {
      // No available provider failure scenario, use the server endpoint
      return this._getContractInstance(false, true)[functionName](
        ...args
      );
    }
    try {
      // We have a web3timeout race because there are certain situations where
      // web3 requests will hang.  e.g. Safari websockets or Infura rate limiting
      // ref: https://developer.apple.com/forums/thread/679576
      // res: https://github.com/tilt-dev/tilt/issues/4746

      const web3timeout = new Promise(resolve => setTimeout(resolve, 1500));
      // eslint-disable-next-line no-async-promise-executor
      const web3result = new Promise(async (resolve) => {
        try {
          resolve(await this._getContractInstance(false)[functionName](...args));
        } catch {
          resolve(undefined);
        }
      });
      let result: any = await Promise.race([web3timeout, web3result]);
      if (result === undefined) {
        // Fallback provider failure scenario, use the server endpoint
        result = await this._getContractInstance(false, true)[
          functionName
        ](...args);
      }
      return result;
    } catch (e) {
      // try getting from server instead
      return await this._getContractInstance(false, true)[functionName](
        ...args
      );
    }
  }

  /**
   * Get the manifold bridge provider instance
   */
  private _getManifoldBridgeProvider (): ManifoldBridgeProvider {
    if (!this.manifoldBridgeProvider) {
      this.manifoldBridgeProvider = markRaw(
        new ManifoldBridgeProvider(this.networkId)
      );
    }
    return this.manifoldBridgeProvider;
  }

  async errorHandling (error: ContractError) {
    if (
      error.code === 'TRANSACTION_REPLACED' &&
      !error.cancelled &&
      error.replacement
    ) {
      const provider = window.ManifoldEthereumProvider.provider();
      if (!provider) {
        throw new Error(
          'No web3 provider detected, please refresh the page and try again'
        );
      }
      return await provider.getTransaction(error.replacement.hash);
    } else {
      throw error;
    }
  }
}

export default ERC20Contract;
